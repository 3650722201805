import * as React from 'react'
import Layout from '../../components/layout'
import SublinkContainer from '../../components/wisSublink'
import { StaticImage } from 'gatsby-plugin-image'
import '../../css/wis.css'

const WhatIsShamisenIntroduction = () => {
    return (
        <Layout pageTitle='What is shamisen?' subTitle='What is shamisen?' description="An introduction to the Japanese shamisen. Learn about the history, styles of instrument and the different genres of music.">
            <div className='wis-introduction-page sub-wrapper'>
                <SublinkContainer />
                <section className='normal-section'>
                    <h2 className='section-header'>
                        Introduction
                    </h2>
                    <div className='section-content'>
                        <p>
                            So what exactly is shamisen? The shamisen is a plucked chordophone of Japan that has been popular in folk, art, and theatre music since the middle of the 17th century. Alternately known as samisen (in Kyoto and Osaka) and sangen (when played with koto in jiuta chamber music), and now sometimes spelled syamisen, the shamisen has associated with it a large repertoire of music.
                        </p>
                        <div className='static-img-container'>
                            <StaticImage
                                className='normal-flow-img'
                                alt='Several shamisen lined up on a table.'
                                src='../../images/what-is-shamisen/several-shamisen.jpg'
                            />
                        </div>
                        <p>
                            The instrument was first popularized during the artistically vibrant Edo period (1603-1868 CE) by mendicant monks and todo (blind professional musicians organized in guilds specializing in specific genres of music) of 16th and 17th century Japan. In modern Japan, kabuki and geisha performances are some of the few contexts in which traditional shamisen music is heard. Geisha represent a significant portion of the community that studies, performs, and is exposed to shamisen music, especially the kouta genre. Amateurs interested in shamisen can find professional teachers with whom to study, and there are even degree programs in shamisen performance at a few universities.
                        </p>
                    </div>
                </section>
                <section className='normal-section'>
                    <h2 className='section-header'>
                        Shamisen Description
                    </h2>
                    <div className='section-content'>
                        <p>
                            The shamisen is a spike lute, meaning that the neck passes through the walls and interior of the resonator. The resonator (do) is a square wood frame about four inches deep constructed from four slightly arched slats of wood (usually redwood or Chinese quince). The top and bottom openings of this frame are covered with stretched hides (kawa) of dog or cat skin that are glued to it. The long fretless neck (sao) is typically constructed of three joined segments of wood, and can be disassembled for ease of transport. A pegbox (itogura) with three laterally mounted friction tuning pegs (itomaki) tops the neck. At the point where the neck becomes the pegbox a subtle but important pair of features are in place that contribute to the desired sound of this instrument.
                        </p>
                        <p>
                            First, a nut/bridge (kami-goma) made from rolled-up brass runs partially across the neck, so that the two higher-pitched strings pass over it, but not the third string. Secondly, a shallow trough or niche (sawari) is carved across the fingerboard just below the nut. Only the lowest-tuned string actually is in contact with the upper edge of this niche. A buzzing effect, also called sawari, is created when this particular string is vibrating. Three nylon (or, traditionally silk) strings of differing gauges run the length of the instrument. At the top end each is wound around a tuning peg, at the other end each is tied to a silk string holder (neo) that loops around the end stub of the neck (nakagosaki) where it exits from the bottom of the resonator and serves as the tailpiece.
                        </p>
                        <p>
                            A low bridge (koma) is placed under and held in place by the strings on the top face of the resonator. A patch of hide (bachigawa) is glued to the soundboard between the bridge and the rim of the resonator frame to help strengthen the kawa against frequent contact with the sharp edge of the plectrum (bachi) used to pluck the strings. To protect the side of the resonator in contact with the performer's plectrum wielding arm, a decorative embroidered cover (dokake) is tied on.
                        </p>
                    </div>
                </section>
                <section className='normal-section'>
                    <h2 className='section-header'>
                        How shamisen are played
                    </h2>
                    <div className='section-content'>
                        <p>

                            A shamisen player sits in the seiza position (on the knees, legs folded under) on the floor with the resonator resting on the right thigh and the neck at about a 45-degree angle. The bachi is held in the right hand with the forearm resting on the dokake; it is used to strike both the string and the soundboard simultaneously. The thumb of the left hand hooks around the back of the neck leaving the fingers of that hand available to stop the two highest-pitched strings against the fingerboard.

                            The skilled shamisen player, unencumbered by frets, can produce gentle slides and vibrato in addition to any pitch within the range of the string. The instrument will be tuned to complement the vocal range of the singer being accompanied, but is generally around a C4 for the lowest string and F4 and C5 for the middle and highest strings. This tuning pattern, called honchoshi, will often be adjusted to one of two other patterns in the course of a performance: C4 - G4 - C5 (niagari) and C4 - F4 - B-flat4 (sansagari).
                        </p>
                        <div className='static-img-container'>
                            <StaticImage
                                className='normal-flow-img'
                                alt='A chart showing the relationship between the different genres of shamisen.'
                                src='../../images/what-is-shamisen/shamisen-genres.jpg'
                            />
                        </div>
                    </div>
                </section>
                <section className='normal-section'>
                    <h2 className='section-header'>
                        Origins/History/Evolution
                    </h2>
                    <div className='section-content'>
                        <p>
                            The shamisen probably arrived in Japan from the Ryukyu Islands (Okinawa) in the mid-16th century, but was ultimately derived from the Chinese sanxian.Upon arriving in Japan the shamisen was adopted by itinerant biwa (another plucked lute, but with a wood soundboard) players, who quite possibly changed the membrane from the snake skin originally used to more durable cat skin, and added the sawari niche in order to make it sound more like the biwa. Over time, this basic design has been realized in a range of sizes to satisfy the many different musical genres for which the shamisen came to be used.
                        </p>
                        <p>
                        Source: <a href="https://omeka-s.grinnell.edu/s/MusicalInstruments/item/646">Grinnell College Musical Instrument Collection Webpage</a>
                        </p>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default WhatIsShamisenIntroduction
